import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Container = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: ${({ isMobile }) => (isMobile ? '16px' : '32px')};
`

export const ConfirmationCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 600px;
  overflow: hidden;
`

export const PixCard = styled(ConfirmationCard)`
  max-width: 800px;
`

export const CardHeader = styled.div`
  padding: 24px;
  background: #f8fafc;
  border-bottom: 1px solid #e5e5e5;

  h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #1c1c1c;
  }
`

export const CardContent = styled.div`
  padding: 24px;
`

export const OrderDetails = styled.div`
  margin-bottom: 24px;

  .detail-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #e5e5e5;

    &:last-child {
      border-bottom: none;
    }

    span {
      color: #666;
      font-size: 14px;
    }

    strong {
      color: #1c1c1c;
      font-size: 16px;
      font-weight: 600;
    }
  }
`

export const InfoText = styled.div`
  margin-bottom: 24px;

  p {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const Button = styled.button`
  background: #748351;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #5a6840;
  }

  &:disabled {
    background: #e5e5e5;
    cursor: not-allowed;
  }
`

export const ButtonCopy = styled(Button)`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
`

export const ButtonCopied = styled(ButtonCopy)`
  background: #777777;
  cursor: default;

  &:hover {
    background: #777777;
  }
`

export const CountDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  max-width: 300px;
  padding: 10px 30px;
  margin: 0 auto 40px;
  text-align: center;
  font-weight: 600;
  color: #748351;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
`

export const CountDownContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #666;
    font-size: 14px;
    margin-top: 4px;
  }
`

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
`

export const QrCodeinfos = styled.div`
  flex: 1;
  max-width: 400px;

  p {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`

export const ExpiredMessage = styled.div`
  text-align: center;
  color: #666;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 24px;

  p {
    margin-bottom: 16px;
  }
`

export const PaymentStatus = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #748351;
`

export const SuccessMessage = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;

  .success-icon {
    color: #748351;
    margin-bottom: 16px;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #748351;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    color: #666;
  }
`
