'use client'

import { Select } from 'antd'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import CardBrandIcon from './CardBrandIcon'
import * as S from './styles'
import { CreditCard, CreditCardFields } from './types'
import { formatCardNumber, getCardBrand } from './utils/cardUtils'

const { Option } = Select

interface CardFormProps {
  creditCard: CreditCard
  changeCreditCardInfo: (field: CreditCardFields, value: string | number) => void
  installments: any[]
  isLoadingInstallments: boolean
}

const CardForm: React.FC<CardFormProps> = ({
  creditCard,
  changeCreditCardInfo,
  installments,
  isLoadingInstallments
}) => {
  const [focused, setFocused] = useState<CreditCardFields | null>(null)
  const [errors, setErrors] = useState<Partial<Record<CreditCardFields, boolean>>>({})
  const [selectedInstallment, setSelectedInstallment] = useState<any>(null)

  const cardBrand = getCardBrand(creditCard?.card_number || '')

  useEffect(() => {
    if (installments?.length > 0 && !selectedInstallment) {
      const firstInstallment = installments[0]
      setSelectedInstallment(firstInstallment)
      changeCreditCardInfo('installments', firstInstallment.id)
    }
  }, [installments, selectedInstallment, changeCreditCardInfo])

  const handleFocus = (field: CreditCardFields) => {
    setFocused(field)
  }

  const validateField = (field: CreditCardFields, value: string) => {
    switch (field) {
      case 'card_number':
        return value.replace(/\D/g, '').length >= 15
      case 'card_name':
        return value.trim().length >= 3
      case 'card_cvv':
        return value.length >= 3
      default:
        return true
    }
  }

  const handleChange = (field: CreditCardFields, value: string) => {
    changeCreditCardInfo(field, value)
    setErrors((prev) => ({
      ...prev,
      [field]: !validateField(field, value)
    }))
  }

  const handleInstallmentChange = (value: number) => {
    const selected = installments.find((inst) => inst.id === value)
    setSelectedInstallment(selected)
    changeCreditCardInfo('installments', value)
  }

  return (
    <S.FormContainer>
      <S.FormRow>
        <S.InputContainer isFocused={focused === 'card_number'} hasError={errors.card_number}>
          <input
            type="text"
            required
            maxLength={19}
            value={formatCardNumber(creditCard?.card_number || '')}
            onChange={(e) => handleChange('card_number', e.target.value)}
            onFocus={() => handleFocus('card_number')}
            onBlur={() => setFocused(null)}
            placeholder="Número do Cartão"
          />
          <AnimatePresence>
            {cardBrand && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="card-brand-icon">
                <CardBrandIcon brand={cardBrand} />
              </motion.div>
            )}
          </AnimatePresence>
        </S.InputContainer>
      </S.FormRow>

      <S.FormRow>
        <S.InputContainer isFocused={focused === 'card_name'} hasError={errors.card_name}>
          <input
            type="text"
            required
            value={creditCard?.card_name || ''}
            onChange={(e) => handleChange('card_name', e.target.value)}
            onFocus={() => handleFocus('card_name')}
            onBlur={() => setFocused(null)}
            placeholder="Nome no Cartão"
          />
        </S.InputContainer>
      </S.FormRow>

      <S.FormRow className="card-details">
        <S.InputContainer>
          <select
            value={creditCard?.card_month_validate || ''}
            onChange={(e) => changeCreditCardInfo('card_month_validate', e.target.value)}
            onFocus={() => handleFocus('card_month_validate')}
            onBlur={() => setFocused(null)}>
            <option value="" disabled>
              Mês
            </option>
            {Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')).map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>
        </S.InputContainer>

        <S.InputContainer>
          <select
            value={creditCard?.card_year_validate || ''}
            onChange={(e) => changeCreditCardInfo('card_year_validate', e.target.value)}
            onFocus={() => handleFocus('card_year_validate')}
            onBlur={() => setFocused(null)}>
            <option value="" disabled>
              Ano
            </option>
            {Array.from({ length: 20 }, (_, i) => new Date().getFullYear() + i).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </S.InputContainer>

        <S.InputContainer isFocused={focused === 'card_cvv'} hasError={errors.card_cvv}>
          <input
            type="text"
            required
            maxLength={4}
            value={creditCard?.card_cvv || ''}
            onChange={(e) => handleChange('card_cvv', e.target.value.replace(/\D/g, ''))}
            onFocus={() => handleFocus('card_cvv')}
            onBlur={() => setFocused(null)}
            placeholder="CVV"
          />
        </S.InputContainer>
      </S.FormRow>

      <S.FormRow>
        <S.InstallmentContainer>
          <Select
            placeholder="Selecione as parcelas"
            style={{ width: '100%' }}
            value={creditCard?.installments}
            onChange={handleInstallmentChange}
            loading={isLoadingInstallments}
            disabled={isLoadingInstallments}
            dropdownMatchSelectWidth={false}
            className="installment-select">
            {installments?.map((installment) => (
              <Option key={installment.id} value={installment.id}>
                <S.OptionInstallment>
                  <p className="values_installment">
                    {`${installment.installment} de ${new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(installment.amount)}`}
                  </p>
                  <p className={`total_installment ${installment.fees === '(sem juros)' ? 'not_installment' : ''}`}>
                    {installment.fees === '(sem juros)'
                      ? 'Sem juros'
                      : `Total: ${new Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL'
                        }).format(installment.total)}`}
                  </p>
                </S.OptionInstallment>
              </Option>
            ))}
          </Select>
        </S.InstallmentContainer>
      </S.FormRow>
    </S.FormContainer>
  )
}

export default CardForm
