export const translatePaymentMethod = (method: string): string => {
  switch (method) {
    case 'credit_card':
      return 'Cartão de crédito'
    case 'boleto':
      return 'Boleto'
    case 'pix':
      return 'Pix'
    default:
      return method
  }
}
