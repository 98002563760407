import React from 'react'
import styled, { keyframes } from 'styled-components'

interface ModalProps {
  isOpen: boolean
  children: React.ReactNode
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const slideIn = keyframes`
  from { transform: translateY(-50px); }
  to { transform: translateY(0); }
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: ${fadeIn} 0.3s ease-out;
`

const ModalContent = styled.div`
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: ${slideIn} 0.3s ease-out;
`

const ModalTitle = styled.h2`
  color: #748351;
  font-size: 24px;
  margin-bottom: 20px;
`

const ModalMessage = styled.p`
  color: #333;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
`

const ModalIcon = styled.div`
  font-size: 48px;
  margin-bottom: 20px;
  color: #748351;
`

const Modal: React.FC<ModalProps> = ({ isOpen, children }) => {
  if (!isOpen) return null

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalIcon>🔔</ModalIcon>
        <ModalTitle>Aviso Importante</ModalTitle>
        <ModalMessage>{children}</ModalMessage>
      </ModalContent>
    </ModalOverlay>
  )
}

export default Modal
