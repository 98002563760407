'use client'

import { usePrepareCart } from 'hooks/usePrepareCart'
import { ArrowLeft, CreditCard, Receipt, Wallet } from 'lucide-react'
import { useEffect, useState } from 'react'
import OrderSummary from '../OrderSummary'
import Boleto from './Boleto'
import CreditCardComponent from './CreditCard'
import PaymentMethodSelector from './PaymentMethodSelector'
import { Pix } from './Pix'
import * as S from './styles'

interface PaymentMethodProps {
  disabledPix?: boolean
  disabledCreditCard?: boolean
  disabledBoleto?: boolean
  finishOrderByPaymentMethod: any
  error: boolean
  isValidateSubmit?: boolean
  loadingContainer: boolean
  clearIntervalOutside: () => void
  hasMultipleMethods?: boolean
}

export function PaymentMethod({
  disabledPix,
  disabledCreditCard,
  disabledBoleto,
  finishOrderByPaymentMethod,
  error,
  isValidateSubmit = false,
  loadingContainer,
  clearIntervalOutside,
  hasMultipleMethods
}: PaymentMethodProps) {
  const { lastOrderCreated } = usePrepareCart()
  const [selectedMethod, setSelectedMethod] = useState<string | null>(null)
  const [showSelector, setShowSelector] = useState(true)
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)

  useEffect(() => {
    return () => {
      clearIntervalOutside()
    }
  }, [clearIntervalOutside])

  const handleMethodSelect = (method: string) => {
    setSelectedMethod(method)
    setShowSelector(false)
  }

  const handleBackToSelector = () => {
    if (!isProcessingPayment) {
      setShowSelector(true)
      setSelectedMethod(null)
    }
  }

  const getMethodIcon = (method: string) => {
    switch (method) {
      case 'credit_card':
      case 'credit_card_credit_card':
      case 'credit_card_boleto':
        return <CreditCard size={24} />
      case 'pix':
      case 'credit_card_pix':
        return <Wallet size={24} />
      case 'boleto':
        return <Receipt size={24} />
      default:
        return null
    }
  }

  const getMethodTitle = (method: string) => {
    switch (method) {
      case 'credit_card':
        return 'Cartão de Crédito'
      case 'pix':
        return 'Pix'
      case 'boleto':
        return 'Boleto Bancário'
      case 'credit_card_credit_card':
        return 'Dois Cartões de Crédito'
      case 'credit_card_pix':
        return 'Cartão de Crédito + Pix'
      case 'credit_card_boleto':
        return 'Cartão de Crédito + Boleto'
      default:
        return ''
    }
  }

  const renderPaymentComponent = () => {
    switch (selectedMethod) {
      case 'credit_card':
      case 'credit_card_credit_card':
      case 'credit_card_pix':
      case 'credit_card_boleto':
        return (
          <CreditCardComponent
            isValidateSubmit={isValidateSubmit}
            finishOrderByPaymentMethod={finishOrderByPaymentMethod}
            loadingContainer={loadingContainer}
            disabledPix={disabledPix}
            disabledCreditCard={disabledCreditCard}
            disabledBoleto={disabledBoleto}
            paymentMethod={
              selectedMethod as 'credit_card' | 'credit_card_credit_card' | 'credit_card_pix' | 'credit_card_boleto'
            }
          />
        )
      case 'pix':
        return <Pix loadingContainer={loadingContainer} />
      case 'boleto':
        return <Boleto error={error} isValidateSubmit={isValidateSubmit} setError={() => {}} />
      default:
        return null
    }
  }

  return (
    <S.Container>
      <OrderSummary
        items={lastOrderCreated?.items}
        discountFreight={lastOrderCreated?.shippingDiscount}
        discountProduct={lastOrderCreated?.discount}
        freight={lastOrderCreated?.shipping}
        reshipping_value={lastOrderCreated?.reshipping_payer_value}
        subtotal={lastOrderCreated?.subtotal}
        amount={lastOrderCreated?.amount}
        originType={lastOrderCreated?.origin}
        payments={lastOrderCreated?.charges}
      />
      {showSelector ? (
        <PaymentMethodSelector
          selectedMethod={selectedMethod}
          onSelectMethod={handleMethodSelect}
          totalAmount={lastOrderCreated?.amount || 0}
          disabledPix={disabledPix}
          disabledCreditCard={disabledCreditCard}
          disabledBoleto={disabledBoleto}
          hasMultipleMethods={hasMultipleMethods}
        />
      ) : (
        <S.PaymentFormContainer>
          <S.PaymentFormHeader>
            <S.BackButton onClick={handleBackToSelector} style={{ color: '#748351' }} disabled={isProcessingPayment}>
              <ArrowLeft size={24} style={{ color: isProcessingPayment ? '#ccc' : '#748351' }} />
              Formas de pagamento
            </S.BackButton>
            <S.SelectedMethodInfo>
              <span style={{ color: '#748351' }}>{getMethodIcon(selectedMethod || '')}</span>
              <span>{getMethodTitle(selectedMethod || '')}</span>
            </S.SelectedMethodInfo>
          </S.PaymentFormHeader>
          {renderPaymentComponent()}
        </S.PaymentFormContainer>
      )}
    </S.Container>
  )
}

export default PaymentMethod
