export const validateEmail = (email: string): boolean => {
  // Implementação da validação de e-mail
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const validatePhone = (phone: string): boolean => {
  // Implementação da validação de telefone
  // Remove non-digit characters and check length
  const cleanedPhone = phone.replace(/\D/g, '')
  return cleanedPhone.length >= 10 && cleanedPhone.length <= 11
}

export const validateCpf = (cpf: string): boolean => {
  // Implementação da validação de CPF
  cpf = cpf.replace(/\D/g, '')
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false

  let sum = 0
  for (let i = 0; i < 9; i++) sum += parseInt(cpf.charAt(i)) * (10 - i)
  let rest = 11 - (sum % 11)
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.charAt(9))) return false

  sum = 0
  for (let i = 0; i < 10; i++) sum += parseInt(cpf.charAt(i)) * (11 - i)
  rest = 11 - (sum % 11)
  if (rest === 10 || rest === 11) rest = 0
  return rest === parseInt(cpf.charAt(10))
}
