import { StyledCheckbox } from '@cannect/pages/AppointmentScheduling/FormSteps/CheckoutStep/PaymentStep/styles'
import axios from 'axios'
import useMediaQuery from 'hooks/useMediaQuery'
import { noop } from 'lodash'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import { clearMask, formatZipCode } from 'utils/formatters'
import { AddressSchema, AddressSchemaType } from '../utils/addressSchema'
import FormAddress from './FormAddress'
import * as Style from './styles'

export type AddressProps = AddressSchemaType

interface AddressType {
  setIsAllAddress: Dispatch<SetStateAction<boolean>>
  handleAddress: any
}

export function Address({ setIsAllAddress, handleAddress }: AddressType) {
  const [activeBilling, setActiveBilling] = useState(false)
  const [billingAddress, setBillingAddress] = useState<AddressProps>({ address_number: '' })
  const [shippingAddress, setShippingAddress] = useState<AddressProps>({ address_number: '' })
  const [errors, setErrors] = useState<Record<string, string>>({})
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const validateAddressData = (data: AddressProps) => {
    const validationResult = AddressSchema.safeParse(data)
    if (!validationResult.success) {
      const newErrors: Record<string, string> = {}
      validationResult.error.issues.forEach((issue) => {
        newErrors[issue.path[0] as string] = issue.message
      })
      setErrors(newErrors)
      return false
    }
    setErrors({})
    return true
  }

  const changeShippingAddress = ({ fieldToChange, newValue, openLoading = noop, closeLoading = noop }: any) => {
    const newShippingAddress = { ...shippingAddress, [fieldToChange]: newValue }
    setShippingAddress(newShippingAddress)

    handleAddress('shippingAddress', newShippingAddress)
    if (fieldToChange === 'cep' && newValue.length === 9) {
      fetchZipCode(clearMask(newValue), 'shipping', openLoading, closeLoading)
    }

    validateAddressData(newShippingAddress)
  }

  const changeBillingAddress = ({ fieldToChange, newValue, openLoading = noop, closeLoading = noop }: any) => {
    const newBillingAddress = { ...billingAddress, [fieldToChange]: newValue }
    setBillingAddress(newBillingAddress)

    handleAddress('billingAddress', newBillingAddress)

    if (fieldToChange === 'cep' && newValue.length === 9) {
      fetchZipCode(clearMask(newValue), 'billing', openLoading, closeLoading)
    }

    validateAddressData(newBillingAddress)
  }

  async function fetchZipCode(
    cep: string,
    type: 'shipping' | 'billing',
    openLoading: () => void,
    closeLoading: () => void
  ) {
    openLoading()
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      const data = response?.data ?? {}
      if (!data.erro) {
        const updatedAddress = {
          cep: formatZipCode(data.cep ?? ''),
          state: data.uf ?? '',
          street: data.logradouro ?? '',
          district: data.bairro ?? '',
          city: data.localidade ?? ''
        }
        if (type === 'shipping') {
          setShippingAddress((prevAddress) => ({ ...prevAddress, ...updatedAddress }))
        } else {
          setBillingAddress((prevAddress) => ({ ...prevAddress, ...updatedAddress }))
        }
      }
    } catch (err: any) {
      console.error(err)
    } finally {
      closeLoading()
    }
  }

  const isValidAllAddress = () => {
    const isShippingAddressValid = validateAddressData(shippingAddress)
    if (!activeBilling && isShippingAddressValid) {
      setIsAllAddress(true)
    } else if (activeBilling && isShippingAddressValid && validateAddressData(billingAddress)) {
      setIsAllAddress(true)
    } else {
      setIsAllAddress(false)
    }
  }

  useEffect(() => {
    isValidAllAddress()
  }, [shippingAddress, billingAddress, activeBilling])

  return (
    <Style.Wrapper isMobile={isMobile}>
      <Style.Container isMobile={isMobile}>
        <Style.Title>
          Endereço de entrega
          <p>*Endereço de entrega precisa ser o mesmo do comprovante de residência</p>
        </Style.Title>
        <FormAddress
          isMobile={isMobile}
          address={shippingAddress}
          changeAddress={changeShippingAddress}
          errors={errors}
        />
        <>
          <Style.Title>Endereço de cobrança</Style.Title>
          <StyledCheckbox checked={activeBilling} onChange={() => setActiveBilling(!activeBilling)}>
            <span>Adicionar endereço diferente do endereço de entrega</span>
          </StyledCheckbox>
        </>
      </Style.Container>
      {activeBilling && (
        <Style.Container isMobile={isMobile}>
          <Style.Title>Endereço de cobrança</Style.Title>
          <FormAddress
            isMobile={isMobile}
            address={billingAddress}
            changeAddress={changeBillingAddress}
            errors={errors}
          />
        </Style.Container>
      )}
    </Style.Wrapper>
  )
}
