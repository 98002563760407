'use client'

import { ClockCircleOutlined } from '@ant-design/icons'
import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { MOBILE_WIDTH } from '@cannect/utils/constants'
import LoadingContainer from 'components/LoadingContainer'
import { AnimatePresence, motion } from 'framer-motion'
import { usePrepareCart } from 'hooks/usePrepareCart'
import { CheckCircle, Loader } from 'lucide-react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from 'services/api'
import Boleto from '../Boleto'
import { Pix } from '../Pix'
import { CountDown } from '../Pix/CountDown'
import StatusModal from '../StatusModal'
import CardForm from './CardForm'
import * as S from './styles'
import { CreditCard, CreditCardFields, SecondPaymentMethod } from './types'
import { translatePaymentMethod } from './utils/chargeUtils'

const statusTranslations = {
  authorized_pending_capture: 'Autorizada pendente de captura',
  not_authorized: 'Não autorizada',
  captured: 'Capturada',
  partial_capture: 'Capturada parcialmente',
  waiting_capture: 'Aguardando captura',
  refunded: 'Estornada',
  voided: 'Cancelada',
  partial_refunded: 'Estornada parcialmente',
  partial_void: 'Cancelada parcialmente',
  error_on_voiding: 'Erro no cancelamento',
  error_on_refunding: 'Erro no estorno',
  waiting_cancellation: 'Aguardando cancelamento',
  with_error: 'Com erro',
  failed: 'Falha',
  waiting_payment: 'Aguardando pagamento',
  paid: 'Pago',
  pending_refund: 'Aguardando estorno',
  generated: 'Gerado',
  viewed: 'Visualizado',
  underpaid: 'Pago a menor',
  overpaid: 'Pago a maior',
  processing: 'Boleto ainda está em etapa de criação'
}

const formatValue = (value: number): string => {
  const safeValue = isNaN(value) ? 0 : value
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(safeValue)
}

const getStatusTranslation = (status) => statusTranslations[status] || 'Erro de pagamento'

const parseValue = (value: string): number => {
  const numeric = parseFloat(value.replace(/[^\d]/g, '')) / 100
  return isNaN(numeric) ? 0 : numeric
}

interface CreditCardComponentProps {
  isValidateSubmit?: boolean
  loadingContainer: boolean
  disabledPix?: boolean
  disabledCreditCard?: boolean
  disabledBoleto?: boolean
  paymentMethod: 'credit_card' | 'credit_card_credit_card' | 'credit_card_pix' | 'credit_card_boleto'
  finishOrderByPaymentMethod: any
}

const CreditCardComponent: React.FC<CreditCardComponentProps> = ({
  isValidateSubmit,
  loadingContainer,
  paymentMethod,
  finishOrderByPaymentMethod
}) => {
  const { lastOrderCreated, setOrderId: setCartOrderId, clearOrderData } = usePrepareCart()
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const [error, setError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentConfirmed, setPaymentConfirmed] = useState(false)
  const [boletoTransaction, setBoletoTransaction] = useState<any>(null)
  const [, setCopyClipboard] = useState<string>('')

  const history = useHistory()
  const totalAmount = lastOrderCreated?.amount || 0

  const [creditCard, setCreditCard] = useState<CreditCard>({
    installments: 1,
    card_month_validate: '01',
    card_year_validate: new Date().getFullYear().toString(),
    card_cvv: '',
    card_value: totalAmount
  })

  const [secondCreditCard, setSecondCreditCard] = useState<CreditCard>({
    installments: 1,
    card_month_validate: '01',
    card_year_validate: new Date().getFullYear().toString(),
    card_cvv: '',
    card_value: totalAmount / 2
  })

  const [secondPaymentMethod, setSecondPaymentMethod] = useState<SecondPaymentMethod | null>(null)
  const [selectedPercentage, setSelectedPercentage] = useState(50)

  const [installments, setInstallments] = useState<any[]>([])
  const [secondInstallments, setSecondInstallments] = useState<any[]>([])
  const [isLoadingInstallments, setIsLoadingInstallments] = useState(true)
  const [isLoadingSecondInstallments, setIsLoadingSecondInstallments] = useState(true)
  const [isExpired, setIsExpired] = useState(false)

  const [inputValues, setInputValues] = useState({
    first: totalAmount / 2,
    second: totalAmount / 2
  })

  const [orderId, setOrderId] = useState<number | null>(null)
  const [errorDetails, setErrorDetails] = useState<
    | {
        status?: string
        acquirer_message?: string
        card_info?: string
        brand?: string
        paymentMethod?: string
      }[]
    | null
  >(null)

  const copyByClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setCopyClipboard(text)
    setLineIsCopied(true)
    toast.success('Código PIX copiado para a área de transferência')
  }

  const isAllSupplements = lastOrderCreated?.items.every(
    (item: any) => item?.product?.class_id === 6 && item.product?.brand_id === 108
  )
  const originCheck = ['acolhimento-cannect', 'B2C', 'COD B', 'Recipe', 'COD C']

  const [chargeIds, setChargeIds] = useState<string[]>([])
  const [chargeStatuses, setChargeStatuses] = useState<{ [key: string]: string }>({})
  const [charges, setCharges] = useState<any>({})
  const [allChargesProcessed, setAllChargesProcessed] = useState(false)
  const [showPixQRCode, setShowPixQRCode] = useState(false)
  const [lineIsCopied, setLineIsCopied] = useState(false)

  const [pixTransaction, setPixTransaction] = useState<{
    qr_code: string
    qr_code_url: string
    expires_at: string
    charge_id: string
    order_code: string
  } | null>(null)

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (paymentMethod !== 'credit_card') {
      const secondMethodType = paymentMethod.split('_')[2] as 'credit_card' | 'pix' | 'boleto'
      setSecondPaymentMethod({
        type: secondMethodType,
        value: totalAmount / 2
      })
    }
  }, [paymentMethod, totalAmount])

  const fetchInstallments = useCallback(
    async (cardValue: number, isSecondCard: boolean = false) => {
      const setLoading = isSecondCard ? setIsLoadingSecondInstallments : setIsLoadingInstallments
      const setInstallmentsList = isSecondCard ? setSecondInstallments : setInstallments

      setLoading(true)
      try {
        const response = await api.post(`payments/charges/installments/${lastOrderCreated?.id}`, {
          value: cardValue
        })
        if (response.data.success && response.data.installments) {
          setInstallmentsList(response.data.installments)
        }
      } catch (error) {
        console.error('Error fetching installments:', error)
        setInstallmentsList([])
      } finally {
        setLoading(false)
      }
    },
    [lastOrderCreated?.id]
  )

  const updatePaymentDistribution = (percentage: number) => {
    const roundedPercentage = Math.round(percentage)
    setSelectedPercentage(roundedPercentage)

    if (paymentMethod === 'credit_card') {
      const fullAmount = totalAmount
      setInputValues({
        first: fullAmount,
        second: 0
      })
      setCreditCard((prev) => ({ ...prev, card_value: fullAmount }))
      fetchInstallments(fullAmount)
      return
    }

    const firstAmount = (totalAmount * roundedPercentage) / 100
    const secondAmount = totalAmount - firstAmount

    setInputValues({
      first: firstAmount,
      second: secondAmount
    })

    setCreditCard((prev) => ({ ...prev, card_value: firstAmount }))
    if (secondPaymentMethod) {
      setSecondPaymentMethod((prev) => ({ ...prev!, value: secondAmount }))
    }
    if (paymentMethod === 'credit_card_credit_card') {
      setSecondCreditCard((prev) => ({ ...prev, card_value: secondAmount }))
    }

    fetchInstallments(firstAmount)
    if (paymentMethod === 'credit_card_credit_card') {
      fetchInstallments(secondAmount, true)
    }
  }

  useEffect(() => {
    if (lastOrderCreated?.id) {
      updatePaymentDistribution(50)
    }
  }, [lastOrderCreated?.id])

  const changeCreditCardInfo = useCallback(
    (field: CreditCardFields, value: string | number, isSecondCard: boolean = false) => {
      if (isSecondCard) {
        setSecondCreditCard((prev) => ({ ...prev, [field]: value }))
      } else {
        setCreditCard((prev) => ({ ...prev, [field]: value }))
      }
    },
    []
  )

  const handlePaymentSubmit = async () => {
    setIsSubmitting(true)
    setError(false)
    setErrorDetails(null)
    setChargeIds([])
    setChargeStatuses({})
    setAllChargesProcessed(false)
    setShowPixQRCode(false)

    console.log('secondPaymentMethod',secondPaymentMethod)

    try {
      const payments = [
        {
          paymentMethod: { type: 'credit_card' },
          card_number: creditCard.card_number,
          card_name: creditCard.card_name,
          card_month_validate: creditCard.card_month_validate,
          card_year_validate: creditCard.card_year_validate,
          card_cvv: creditCard.card_cvv,
          installments: Number(creditCard.installments),
          remeberCard: false,
          card_value: Math.round(creditCard.card_value! * 100)
        }
      ]

      if (secondPaymentMethod && secondPaymentMethod.type === 'pix') {
        payments.push({
          paymentMethod: { type: 'pix' },
          amount: Math.round(secondPaymentMethod.value * 100)
        })
      } else if (secondPaymentMethod && secondPaymentMethod.type === 'boleto') {
        payments.push({
          paymentMethod: { type: 'boleto' },
          amount: Math.round(secondPaymentMethod.value * 100)
        })
      } else if (secondPaymentMethod && secondPaymentMethod.type === 'credit') {
        payments.push({
          paymentMethod: { type: 'credit_card' },
          card_number: secondCreditCard.card_number,
          card_name: secondCreditCard.card_name,
          card_month_validate: secondCreditCard.card_month_validate,
          card_year_validate: secondCreditCard.card_year_validate,
          card_cvv: secondCreditCard.card_cvv,
          installments: Number(secondCreditCard.installments),
          remeberCard: false,
          card_value: Math.round(secondCreditCard.card_value! * 100)
        })
      }

      const response = await api.post('/payments/charges', {
        order_id: lastOrderCreated.id,
        payments
      })

      if (response.data.success && response.data.responses && response.data.responses.length > 0) {
        const paymentResponses = response.data.responses
        const newChargeIds: string[] = []
        const newCharges: any = {}

        const errors = []
        let pixResponse = null
        let boletoResponse = null

        for (let i = 0; i < paymentResponses.length; i++) {
          const paymentResponse = paymentResponses[i]
          if (paymentResponse.last_transaction && paymentResponse.last_transaction.success) {
            newCharges[paymentResponse.id] = paymentResponse
            newChargeIds.push(paymentResponse.id)
            if (payments[i].paymentMethod.type === 'pix') {
              pixResponse = paymentResponse
            }

            if (payments[i].paymentMethod.type === 'boleto') {
              boletoResponse = paymentResponse
            }

            setCharges(newCharges)
          } else {
            const transaction = paymentResponse.last_transaction
            const { card } = transaction
            errors.push({
              paymentMethod: payments[i].paymentMethod.type,
              status: transaction.status,
              acquirer_message: transaction.acquirer_message,
              card_info: card ? `${card.first_six_digits}...${card.last_four_digits}` : undefined,
              brand: card ? card.brand : undefined
            })
          }
        }

        if (errors.length > 0) {
          setErrorDetails(errors)
          throw new Error('Falha em um ou mais métodos de pagamento')
        } else {
          setChargeIds(newChargeIds)
          setOrderId(lastOrderCreated.id)
          const documents = response.data?.documents

          const documentList = documents?.reduce(
            (acc, doc) => {
              acc[doc.type] = doc
              return acc
            },
            {} as Record<string, any>
          )

          if (!isAllSupplements) {
            const validateAnvisa = documentList?.ANVISA !== undefined || documentList?.PROCURACAO !== undefined

            if (originCheck.some((origin) => origin === lastOrderCreated?.originCheckout) && !validateAnvisa) {
              const validator = await api.get(`anvisaAuth/order/${lastOrderCreated.id}`)

              if (validator?.data?.success && validator?.data?.message === 'waitingDocs') {
                const response = await api.post('anvisaAuth/launchAnvisa', {
                  order_id: lastOrderCreated.id
                })
              } else if (validator?.data?.success && validator?.data?.message === 'inexist') {
                const response = await api.post('anvisaAuth/clickAutomation', {
                  order_id: lastOrderCreated.id
                })
              }
            }
          }
          if (pixResponse) {
            setShowPixQRCode(true)
            setPixTransaction({
              qr_code: pixResponse.last_transaction.qr_code,
              qr_code_url: pixResponse.last_transaction.qr_code_url,
              expires_at: pixResponse.last_transaction.expires_at,
              charge_id: pixResponse.id,
              order_code: pixResponse.code
            })
          }

          if (boletoResponse) {
            setBoletoTransaction(boletoResponse.last_transaction)
          }

          setIsProcessingPayment(true)
        }
      } else {
        throw new Error('Falha no processamento do pagamento')
      }
    } catch (err) {
      console.error('Erro durante o pagamento:', err)
      setError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleValueChange = (value: string, isSecondCard: boolean = false) => {
    const numericValue = parseValue(value)

    if (isSecondCard) {
      setInputValues((prev) => ({
        first: prev.first,
        second: numericValue
      }))

      if (secondPaymentMethod) {
        setSecondPaymentMethod((prev) => ({ ...prev!, value: numericValue }))
      }
      if (paymentMethod === 'credit_card_credit_card') {
        setSecondCreditCard((prev) => ({ ...prev, card_value: numericValue }))
      }

      const newPercentage = Math.round((numericValue / totalAmount) * 100)
      setSelectedPercentage(100 - newPercentage)

      const firstAmount = totalAmount - numericValue
      setCreditCard((prev) => ({ ...prev, card_value: firstAmount }))
      setInputValues((prev) => ({ ...prev, first: firstAmount }))

      fetchInstallments(firstAmount)
      fetchInstallments(numericValue, true)
    } else {
      setInputValues((prev) => ({
        first: numericValue,
        second: prev.second
      }))

      setCreditCard((prev) => ({ ...prev, card_value: numericValue }))

      const newPercentage = Math.round((numericValue / totalAmount) * 100)
      setSelectedPercentage(newPercentage)

      const secondAmount = totalAmount - numericValue
      if (secondPaymentMethod) {
        setSecondPaymentMethod((prev) => ({ ...prev!, value: secondAmount }))
      }
      if (paymentMethod === 'credit_card_credit_card') {
        setSecondCreditCard((prev) => ({ ...prev, card_value: secondAmount }))
      }
      setInputValues((prev) => ({ ...prev, second: secondAmount }))

      fetchInstallments(numericValue)
      if (paymentMethod === 'credit_card_credit_card') {
        fetchInstallments(secondAmount, true)
      }
    }
  }

  const handleInputBlur = (value: string, isSecondCard: boolean = false) => {
    const numericValue = parseValue(value)
    if (numericValue > totalAmount) {
      handleValueChange(formatValue(totalAmount), isSecondCard)
    }
  }

  const percentageOptions = [10, 25, 50, 75, 90]

  const renderPercentageSection = (isSecondCard: boolean = false) => {
    if (paymentMethod === 'credit_card') return null
    if (!isSecondCard || paymentMethod === 'credit_card_credit_card') {
      const currentPercentage = isSecondCard ? 100 - selectedPercentage : selectedPercentage
      const currentValue = isSecondCard ? inputValues.second : inputValues.first

      return (
        <S.PercentageSection>
          <S.PercentageTitle>
            {isSecondCard ? 'Porcentagem do segundo cartão' : 'Porcentagem do cartão'}
          </S.PercentageTitle>
          <S.PercentageButtons>
            {percentageOptions.map((percentage) => (
              <S.PercentageButton
                key={percentage}
                isSelected={currentPercentage === percentage}
                onClick={() => updatePaymentDistribution(isSecondCard ? 100 - percentage : percentage)}>
                {percentage}%
              </S.PercentageButton>
            ))}
            <S.CustomValueInput>
              <input
                type="text"
                value={formatValue(currentValue)}
                onChange={(e) => handleValueChange(e.target.value, isSecondCard)}
                onBlur={(e) => handleInputBlur(e.target.value, isSecondCard)}
              />
            </S.CustomValueInput>
          </S.PercentageButtons>
        </S.PercentageSection>
      )
    }
    return null
  }

  const handleExpiration = () => {
    setIsExpired(true)
    setIsProcessingPayment(false)
  }

  useEffect(() => {
    const checkPaymentStatus = async () => {
      if (orderId && chargeIds.length > 0) {
        try {
          const statusPromises = chargeIds.map((chargeId) => api.get(`/payments/charge/${orderId}/${chargeId}`))
          const responses = await Promise.all(statusPromises)

          const newChargeStatuses = { ...chargeStatuses }

          let allPaid = true
          let cardPaid = false
          let boletoGenerated = false

          responses.forEach((response, index) => {
            if (response.data.success) {
              const { status } = response.data.charge
              newChargeStatuses[chargeIds[index]] = status

              if (index === 0) {
                cardPaid = status === 'paid'
              }
              if (paymentMethod === 'credit_card_boleto' && index === 1) {
                const lastTransaction = response.data.charge.transactions.find(
                  (t: any) => t.external_id === response.data.charge.last_transaction_external_id
                )
                boletoGenerated = lastTransaction && lastTransaction.status === 'generated'
              } else {
                allPaid = allPaid && status === 'paid'
              }
            }
          })

          setChargeStatuses(newChargeStatuses)

          if (
            (paymentMethod === 'credit_card_credit_card' && allPaid) ||
            (paymentMethod === 'credit_card_pix' && allPaid) ||
            (paymentMethod === 'credit_card_boleto' && cardPaid && boletoGenerated) ||
            (paymentMethod === 'credit_card' && allPaid)
          ) {
            setAllChargesProcessed(true)
            setPaymentConfirmed(true)
            setIsProcessingPayment(false)
            setShowPixQRCode(false)
            if (pollingIntervalRef.current) {
              clearInterval(pollingIntervalRef.current)
            }
            if (paymentMethod === 'credit_card_boleto') {
              localStorage.removeItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
              setCartOrderId(null)
              clearOrderData()
              setTimeout(() => {
                history.push(`/pagamento/boleto/${responses[1].data.order.id}/${responses[1].data.charge.external_id}`)
              }, 3000)
            } else {
              localStorage.removeItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
              setCartOrderId(null)
              clearOrderData()
              setTimeout(() => {
                history.push(`/analise-cannect-wa?cart_id=${responses[0].data.order.uuid}`)
              }, 3000)
            }
          }
        } catch (error) {
          console.error('Error checking payment status:', error)
        }
      }
    }

    if ((isProcessingPayment || showPixQRCode) && orderId && chargeIds.length > 0 && !pollingIntervalRef.current) {
      console.log('Starting payment status polling...')
      checkPaymentStatus() // Verifica imediatamente
      pollingIntervalRef.current = setInterval(checkPaymentStatus, 5000)
    }

    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current)
        pollingIntervalRef.current = null
      }
    }
  }, [isProcessingPayment, showPixQRCode, orderId, chargeIds, history])

  console.log('charges:', charges)

  const renderContent = () => {
    if (showPixQRCode && pixTransaction) {
      return (
        <AnimatePresence>
          <S.PixQRCodeContainer>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <S.CountDownContainer>
                <ClockCircleOutlined style={{ fontSize: '24px', paddingRight: '10px' }} />
                <S.CountDownContent>
                  <CountDown expiresAt={pixTransaction?.expires_at} onExpire={handleExpiration} />
                  <p>para expirar</p>
                </S.CountDownContent>
              </S.CountDownContainer>
              <S.QrCodeContainer>
                <img src={pixTransaction?.qr_code_url} alt="QR Code PIX" />
                <S.QrCodeinfos>
                  <p>1. Abra o app do seu banco ou instituição financeira.</p>
                  <p>2. Escolha a opção de pagamento via PIX.</p>
                  <p>3. Escaneie o QR Code ou copie e cole o código PIX.</p>
                  <p>4. Confirme as informações e finalize o pagamento.</p>
                  <p>5. Após a confirmação, você será redirecionado para a página de resumo do pedido.</p>
                </S.QrCodeinfos>
              </S.QrCodeContainer>
              <S.ButtonCopy onClick={() => copyByClipboard(pixTransaction?.qr_code)}>Copiar código PIX</S.ButtonCopy>
            </motion.div>
          </S.PixQRCodeContainer>
        </AnimatePresence>
      )
    }

    if (isProcessingPayment) {
      return (
        <S.ProcessingPayment initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <S.LoadingSpinner />
          <h3>Processando pagamento...</h3>
          {boletoTransaction ? (
            <p>Por favor, aguarde enquanto geramos seu boleto. Isso pode levar alguns minutos.</p>
          ) : (
            <p>Por favor, aguarde enquanto processamos seu pagamento. Isso pode levar alguns minutos.</p>
          )}
          {!boletoTransaction &&
            Object.entries(charges).map(([chargeId, status]) => (
              <p key={chargeId}>
                Status do pagamento do {translatePaymentMethod(charges[chargeId].payment_method)}{' '}
                {charges[chargeId].payment_method === 'credit_card' &&
                  `final ${charges[chargeId].last_transaction.card.last_four_digits}`}{' '}
                :{' '}
                {chargeStatuses && chargeStatuses[chargeId]
                  ? chargeStatuses[chargeId] === 'paid'
                    ? 'Pago'
                    : chargeStatuses[chargeId] === 'pending' || chargeStatuses[chargeId] === 'wait_paid'
                      ? 'Processando'
                      : chargeStatuses[chargeId] === 'failed'
                        ? 'Falhou'
                        : chargeStatuses[chargeId] === 'processing'
                          ? 'Processando'
                          : chargeStatuses[chargeId] === 'cancel'
                            ? 'Cancelado'
                            : chargeStatuses[chargeId]
                  : 'Processando'}
              </p>
            ))}
          <p>Você será redirecionado automaticamente após a confirmação do pagamento.</p>
        </S.ProcessingPayment>
      )
    }

    if (paymentConfirmed) {
      return (
        <S.SuccessMessage initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0 }}>
          <CheckCircle size={48} className="success-icon" />
          <h3>Pagamento confirmado!</h3>
          <p>Seu pedido foi processado com sucesso.</p>
          <p>Você será redirecionado para a tela de status em alguns segundos.</p>
        </S.SuccessMessage>
      )
    }

    return (
      <>
        <S.PaymentMethodsGrid>
          <S.PaymentMethodColumn>
            {renderPercentageSection()}
            <S.CardSection>
              <S.CardHeader>
                <div>
                  <h3>Cartão de Crédito</h3>
                  <div>
                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                      creditCard.card_value || 0
                    )}
                  </div>
                  {paymentMethod !== 'credit_card' && <small>{selectedPercentage}%</small>}
                </div>
              </S.CardHeader>
              <S.CardContent isMobile={isMobile}>
                <CardForm
                  creditCard={creditCard}
                  changeCreditCardInfo={changeCreditCardInfo}
                  installments={installments}
                  isLoadingInstallments={isLoadingInstallments}
                />
              </S.CardContent>
            </S.CardSection>
          </S.PaymentMethodColumn>

          {secondPaymentMethod && (
            <S.PaymentMethodColumn>
              {paymentMethod === 'credit_card_credit_card' && renderPercentageSection(true)}
              <S.CardSection>
                {paymentMethod === 'credit_card_credit_card' && (
                  <S.CardHeader>
                    <div>
                      <h3>
                        {paymentMethod === 'credit_card_credit_card'
                          ? 'Segundo Cartão de Crédito'
                          : paymentMethod === 'credit_card_pix'
                            ? 'PIX'
                            : 'Boleto'}
                      </h3>
                      <div>
                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                          secondPaymentMethod.value
                        )}
                      </div>
                      {paymentMethod === 'credit_card_credit_card' && <small>{100 - selectedPercentage}%</small>}
                    </div>
                  </S.CardHeader>
                )}
                <S.CardContent isMobile={isMobile}>
                  {paymentMethod === 'credit_card_credit_card' ? (
                    <CardForm
                      creditCard={secondCreditCard}
                      changeCreditCardInfo={(field, value) => changeCreditCardInfo(field, value, true)}
                      installments={secondInstallments}
                      isLoadingInstallments={isLoadingSecondInstallments}
                    />
                  ) : paymentMethod === 'credit_card_pix' ? (
                    <Pix
                      error={error}
                      loadingContainer={false}
                      isCardPix
                      pixAmount={secondPaymentMethod?.value || 0}
                      setError={setError}
                      onErrorDetails={setErrorDetails}
                      finishOrderByPaymentMethod={finishOrderByPaymentMethod}
                    />
                  ) : (
                    <Boleto
                      error={error}
                      isValidateSubmit={isValidateSubmit}
                      isCardBoleto
                      setError={setError}
                      boletoAmount={secondPaymentMethod?.value || 0}
                    />
                  )}
                </S.CardContent>
              </S.CardSection>
            </S.PaymentMethodColumn>
          )}
        </S.PaymentMethodsGrid>

        <S.ButtonContainer>
          <S.BtnFinishOrder onClick={handlePaymentSubmit} disabled={isValidateSubmit || isSubmitting}>
            {isSubmitting ? (
              <>
                <Loader className="mr-2 animate-spin" />
                Processando...
              </>
            ) : (
              'Finalizar pedido'
            )}
          </S.BtnFinishOrder>
        </S.ButtonContainer>
      </>
    )
  }

  if (loadingContainer) {
    return <LoadingContainer loading />
  }

  return (
    <S.Container isMobile={isMobile}>
      <AnimatePresence mode="wait">{renderContent()}</AnimatePresence>

      <StatusModal
        onClickButton={() => {
          setError(false)
          setErrorDetails(null)
        }}
        variant="error"
        icon={<RiErrorWarningFill />}
        textButton="Tentar Novamente"
        isOpen={error}
        onClose={() => {
          setError(false)
          setErrorDetails(null)
        }}
        title="Houve um erro ao processar seu pagamento"
        text={
          errorDetails ? (
            <>
              {errorDetails.map((error, index) => (
                <div key={index} className="error-group">
                  <h4>
                    {error.paymentMethod === 'info'
                      ? 'Informação'
                      : error.paymentMethod === 'credit_card'
                        ? 'Erro no cartão de crédito'
                        : `Erro no ${error.paymentMethod}`}
                  </h4>
                  {error.paymentMethod !== 'info' ? (
                    <>
                      <div className="detail-item">
                        <span className="label">Status:</span>
                        <span className="value">{getStatusTranslation(error.status)}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Mensagem:</span>
                        <span className="value">{error.acquirer_message}</span>
                      </div>
                      {error.card_info && (
                        <div className="detail-item">
                          <span className="label">Cartão:</span>
                          <div className="card-info">
                            <span className="value">{error.card_info}</span>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="detail-item">
                      <div className="card-info">
                        <span className="value">{error.card_info}</span>
                      </div>
                      <span className="value" style={{ marginTop: '8px' }}>
                        {error.acquirer_message}
                      </span>
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            'Por favor, revise seus dados e tente novamente'
          )
        }
      />
    </S.Container>
  )
}

export default CreditCardComponent
