import useMediaQuery from 'hooks/useMediaQuery'
import React, { useEffect, useState } from 'react'
import { MOBILE_WIDTH } from 'utils/constants'
import { CustomerSchema, CustomerSchemaType } from '../utils/customerSchema'
import FormCustomer from './FormCustomer'
import * as Style from './styles'

export type CustomerProps = CustomerSchemaType

interface CustomerType {
  customer: CustomerProps
  setCostumer: React.Dispatch<React.SetStateAction<CustomerProps>>
  setIsAllCustomerData: React.Dispatch<React.SetStateAction<boolean>>
}

export function Customer({ customer, setCostumer, setIsAllCustomerData }: CustomerType) {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const [errors, setErrors] = useState<Record<string, string>>({})

  const validateCustomerData = (data: CustomerProps) => {
    const validationResult = CustomerSchema.safeParse(data)
    if (!validationResult.success) {
      const newErrors: Record<string, string> = {}
      validationResult.error.issues.forEach((issue) => {
        // Não adiciona erro para phone_residencial se estiver vazio
        if (issue.path[0] === 'phone_residencial' && !data.phone_residencial) {
          return
        }
        newErrors[issue.path[0] as string] = issue.message
      })
      setErrors(newErrors)
      // Verifica se há erros excluindo o phone_residencial vazio
      const hasErrors = Object.entries(newErrors).some(([key, value]) => {
        if (key === 'phone_residencial' && !data.phone_residencial) {
          return false
        }
        return value
      })
      setIsAllCustomerData(!hasErrors)
    } else {
      setErrors({})
      setIsAllCustomerData(true)
    }
  }

  useEffect(() => {
    if (customer?.name) {
      setCostumer({
        name: customer?.name,
        email: customer?.email,
        cpf: customer?.cpf,
        birthday: customer?.birthday,
        gender: customer?.gender,
        phone: customer?.phone,
        phone_residencial: customer?.phone_residencial || ''
      })

      validateCustomerData(customer)
    }
  }, [])

  const changeCustomerData = ({ fieldToChange, newValue, error }: any) => {
    const newCustomerData = { ...customer, [fieldToChange]: newValue }
    setCostumer(newCustomerData)
    setErrors((prev) => ({ ...prev, [fieldToChange]: error }))
    validateCustomerData(newCustomerData)
  }

  useEffect(() => {
    if (Object.values(customer).some((value) => value)) {
      validateCustomerData(customer)
    }
  }, [customer])

  return (
    <Style.Wrapper isMobile={isMobile}>
      <Style.Container isMobile={isMobile}>
        <Style.Title>
          Dados pessoais
          <p>*Confirme seus dados para a entrega</p>
        </Style.Title>
        <FormCustomer isMobile={isMobile} customer={customer} changeCustomer={changeCustomerData} errors={errors} />
      </Style.Container>
    </Style.Wrapper>
  )
}
