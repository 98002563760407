import { UserStatus } from 'components/UserStatus'
import NotAuthorized from 'pages/NotAuthorized'
import { Route as ReactDOMRoute, Redirect } from 'react-router-dom'
import { rolesDoctor, rolesPatientOrDoctor } from '../hooks/useSessionUser'
import { useTokenApi } from '../hooks/useTokenApi'
import { defaultsHeadersAxios } from '../services/api'

export function RoutePatientOrDoctor({ component: Component, verifyStatus, ...rest }: any) {
  const { tokenApi } = useTokenApi()
  defaultsHeadersAxios(tokenApi as string)
  const token = localStorage.getItem('@CANNECT_TOKEN_API')
  // console.log(token)

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return rolesPatientOrDoctor() && tokenApi ? (
          <>
            {/* {verifyStatus && <UserStatus />} */}
            <Component />
          </>
        ) : (
          <Redirect
            to={{
              // eslint-disable-next-line no-nested-ternary
              pathname: !tokenApi ? '/login' : rolesPatientOrDoctor() ? '/' : '/login',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}

export function RouteDoctor({ component: Component, verifyStatus, ...rest }: any) {
  const { tokenApi } = useTokenApi()
  defaultsHeadersAxios(tokenApi as string)

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        // eslint-disable-next-line no-nested-ternary
        return rolesDoctor() && tokenApi ? (
          <>
            {verifyStatus && <UserStatus />}
            <Component />
          </>
        ) : tokenApi ? (
          <NotAuthorized />
        ) : (
          <Redirect
            to={{
              // pathname: rolesDoctor() ? '/' : '/login',
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }}
    />
  )
}
