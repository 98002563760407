import { isValid, parse } from 'date-fns'
import { z } from 'zod'
import { validateCpf } from './validators'

const requiredMessage = 'Campo obrigatório'
const nonEmptyStringSchema = z.string({ required_error: requiredMessage }).min(1, requiredMessage)

export const validatePhone = /^(\d{10,11})$/
export const validateResidentialPhone = /^(\d{10})$/

export const CustomerSchema = z.object({
  name: nonEmptyStringSchema,
  email: z.string({ required_error: requiredMessage }).email('Digite um e-mail válido'),
  cpf: z.string().refine((value) => validateCpf(value), {
    message: 'CPF Inválido'
  }),
  birthday: z.string().refine(
    (value) => {
      // Check if it's already in ISO format
      if (value.includes('-')) {
        return isValid(new Date(value))
      }
      // If it's in DD/MM/YYYY format
      const date = parse(value, 'dd/MM/yyyy', new Date())
      return isValid(date)
    },
    {
      message: 'Digite uma data válida'
    }
  ),
  phone: nonEmptyStringSchema.refine(
    (value) => {
      const digits = value.replace(/\D/g, '')
      return digits.length === 10 || digits.length === 11
    },
    {
      message: 'Celular deve ter 10 ou 11 dígitos'
    }
  ),
  phone_residencial: z
    .string()
    .regex(validateResidentialPhone, 'Telefone residencial deve ter 10 dígitos')
    .optional()
    .or(z.literal('')),
  gender: z.enum(['M', 'F']).nullable().optional()
})

export type CustomerSchemaType = z.infer<typeof CustomerSchema>
