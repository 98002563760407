import React from 'react'
import styled from 'styled-components'
import { formatReal } from 'utils/formatters'

interface OrderSummaryProps {
  items: any[]
  discountFreight: number
  discountProduct: number
  freight: number
  reshipping_value: number
  subtotal: number
  amount: number
  originType: string
  payments: any[]
}

const OrderSummary: React.FC<OrderSummaryProps> = ({
  items,
  discountFreight,
  discountProduct,
  freight,
  reshipping_value,
  subtotal,
  amount,
  originType,
  payments
}) => {
  return (
    <SummaryContainer>
      <SummaryTitle>Resumo do Pedido</SummaryTitle>
      <SummaryContent>
        <SummaryRow>
          <span>Subtotal</span>
          <span>{formatReal(subtotal)}</span>
        </SummaryRow>
        {freight > 0 && (
          <SummaryRow>
            <span>Frete</span>
            <span>{formatReal(freight)}</span>
          </SummaryRow>
        )}
        {discountFreight > 0 && (
          <SummaryRow>
            <span>Desconto no frete</span>
            <span>- {formatReal(discountFreight)}</span>
          </SummaryRow>
        )}
        {discountProduct > 0 && (
          <SummaryRow>
            <span>Desconto nos produtos</span>
            <span>- {formatReal(discountProduct)}</span>
          </SummaryRow>
        )}
        {reshipping_value > 0 && (
          <SummaryRow>
            <span>Taxa de reenvio</span>
            <span>{formatReal(reshipping_value)}</span>
          </SummaryRow>
        )}
        <TotalRow>
          <span>Total</span>
          <span>{formatReal(amount)}</span>
        </TotalRow>
      </SummaryContent>
    </SummaryContainer>
  )
}

const SummaryContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px;
  margin-bottom: 24px;
`

const SummaryTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  color: #1c1c1c;
  margin-bottom: 16px;
`

const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #4a4a4a;
`

const TotalRow = styled(SummaryRow)`
  font-size: 18px;
  font-weight: 600;
  color: #1c1c1c;
  border-top: 1px solid #e5e5e5;
  padding-top: 12px;
  margin-top: 8px;
`

export default OrderSummary
