import api from '@cannect/services/api'
import Banner from 'components/Banner'
import useMediaQuery from 'hooks/useMediaQuery'
import { usePrepareCart } from 'hooks/usePrepareCart'
import Button from 'new-components/Button'
import Heading from 'new-components/Heading'
import Modal from 'new-components/Modal'
import { useEffect, useState } from 'react'
import { FcApproval } from 'react-icons/fc'
import { useHistory } from 'react-router'
import { MOBILE_WIDTH } from 'utils/constants'
import useQueryString from '../../hooks/useQueryString'
import Frete from './Frete'
import ProductList from './Product'
import { Resume } from './Resume'
import Step from './Steps'
import * as Style from './styles'
import { ContainerResume, ContentModal, WrapperModal } from './styles'

function PageCartProducts() {
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { isUpdate, updateCart, lastOrderCreated, setOrderId, clearOrderData } = usePrepareCart()
  const { filters } = useQueryString()
  const withFilter = filters?.cart_id
  const history = useHistory()
  const itens = lastOrderCreated?.items
  const isModalPayment = lastOrderCreated?.payments?.some((payment: any) => payment.status === 'paid')
  const [isModal, setIsModal] = useState(isModalPayment)
  const [openModalCupom, setCloseModalCupom] = useState(false)
  const userId = lastOrderCreated?.person?.id

  useEffect(() => {
    const getOrders = async () => {
      const { data } =
        !userId && !withFilter ? await api.get(`/order_last_paid`) : await api.get(`/order_last_paid/${userId}`)
      if (Object.keys(data.order).length === 0) {
        setCloseModalCupom(true)
      }
    }
    getOrders()
  }, [])

  useEffect(() => {
    if (isModalPayment) {
      localStorage.removeItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
      setOrderId(null)
      clearOrderData()
    }
  })

  const closeModal = () => {
    setIsModal(false)
    if (withFilter) {
      history.push(`/analise-cannect-wa2?cart_id=${withFilter}`)
    } else {
      history.push(`/analise-cannect`)
    }
  }

  const redirect = () => {
    if (withFilter) {
      history.push(`/documentos-wa2?cart_id=${withFilter}`)
    } else {
      handleNextCart()
    }
  }

  const goToProducts = () => {
    history.push(`/produtos/1`)
  }

  const handleNextCart = () => {
    const validateFlowers = itens.filter((item: any) => item?.product?.shape_id === 29)
    const produtosFormatados = itens
      .map((produto: any) => `*Produto:* ${produto.product?.id} - ${produto.product?.name}`)
      .join('\n')
    const numeroCliente = '5511982711992'

    if (validateFlowers.length > 0) {
      const mensagem = `Olá! Fui redirecionado porque gostaria de fazer um pedido que contém flores medicinais. Aqui está a lista dos produtos selecionados:\n${produtosFormatados}`
      const url = `https://api.whatsapp.com/send?phone=${numeroCliente}&text=${encodeURIComponent(mensagem)}`
      window.open(url, '_blank')
    } else {
      history.push('/documentos')
    }
  }

  const isAllSupplements = itens.every((item: any) => item?.product?.class_id === 6 && item.product?.brand_id === 108)

  return (
    <Style.Container isMobile={isMobile}>
      <Step />
      <ProductList />
      {!isAllSupplements && (
        <>
          <Banner title="IMPORTANTE" variant="warning">
            <p>
              Por orientação da Anvisa, os médicos devem prescrever a quantidade de produto para o período de um ano de
              tratamento. De qualquer forma, você pode fazer a compra parcial. Em caso de dúvida sobre a quantidade,
              fale com o seu médico.
            </p>
          </Banner>
          <Banner title="IMPORTANTE" variant="warning">
            <p>O prazo de entrega é de 25 dias a partir da confirmação do pagamento.</p>
          </Banner>
        </>
      )}

      <Banner title="ATENÇÃO" variant="info">
        <p>Preço indicado pelo fornecedor</p>
      </Banner>
      <ContainerResume isMobile={isMobile}>
        {lastOrderCreated?.origin !== 'REENVIO' && <Frete />}
        <Resume />
      </ContainerResume>
      {!isAllSupplements && (
        <Banner title="ATENÇÃO">
          <p>
            Lembre-se que ao pedir medicamentos você deverá fornecer uma receita médica descrevendo o nome e quantidade
            dos mesmos.
          </p>
        </Banner>
      )}

      <Style.ButtonContainer>
        <Style.ButtonContinue onClick={redirect} disabled={isUpdate || itens.length <= 0}>
          {isUpdate ? 'Atualizando...' : 'Continuar'}
        </Style.ButtonContinue>
      </Style.ButtonContainer>
      <Modal isOpen={isModal} setOpenModal={closeModal}>
        <WrapperModal>
          <FcApproval size={40} />
          <Heading elementType="h2" defaultColor="darkGrey" fontSize="20px" verticalSpace="small">
            Obrigado!
          </Heading>
          <ContentModal>
            <p>O seu pedido já foi processado e pago com sucesso.</p>
            <Button onClick={() => goToProducts()}>Buscar novos produtos</Button>
            <Button onClick={() => closeModal()}>Ver detalhes do pedido finalizado</Button>
          </ContentModal>
        </WrapperModal>
      </Modal>
    </Style.Container>
  )
}

export default PageCartProducts
