import styled from 'styled-components'

export const Container = styled.div<any>`
  background: #f6f6f6;
  border-radius: 4px;
  padding: 26px 35.17px 16px 26px;
`

export const Title = styled.h2`
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;

  color: #000000;
  margin-bottom: 30px;

  p {
    margin-top: 16px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #777777;
    text-transform: none;
  }
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 23px;
  input {
    background: #ffffff;
    border: 1px solid #dddddd;
    height: 40px;

    font-weight: 400;
    padding-left: 8px;
  }

  select {
    background: #ffffff;
    border: 1px solid #dddddd;
    height: 40px;

    font-weight: 300;
    padding-left: 8px;
    margin-bottom: 5px;
  }

  span {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;

    color: #777777;
    margin-bottom: 5px;
  }

  .input-column {
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 16px;
    }
  }

  .input-groups {
    display: flex;
    gap: 25px;
    margin-bottom: 26px;

    div {
      display: flex;
      flex-direction: column;
      width: 100%;

      input {
        width: 100%;
        margin-bottom: 5px;
      }
    }
  }
`

export const ButtonFinishedOrder = styled.button`
  background: #c5581b;
  border-radius: 2px;
  border: none;
  height: 40px;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 19px;
  color: #ffffff;
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;

  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-left: 10px;
    color: #777777;
  }
`

export const ErrorMessage = styled.p`
  color: #ff0000;
  font-size: 12px;
`
