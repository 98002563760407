import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'

interface Props {
  expiresAt?: string
  onExpire: () => void
}

export function CountDown({ expiresAt, onExpire }: Props) {
  const [timeLeft, setTimeLeft] = useState<string>('')

  useEffect(() => {
    if (expiresAt) {
      const expirationTime = new Date(expiresAt).getTime()

      const interval = setInterval(() => {
        const now = new Date().getTime()
        const distance = expirationTime - now

        if (distance <= 0) {
          clearInterval(interval)
          onExpire()
          return
        }

        // Calculate minutes and seconds
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        // Format the time string as MM:SS
        const timeString = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`

        setTimeLeft(timeString)
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [expiresAt, onExpire])

  return (
    <Row gutter={16}>
      <Col span={12}>
        <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#748351' }}>{timeLeft}</div>
      </Col>
    </Row>
  )
}
