import styled from 'styled-components'

export const Container = styled.div`
  max-width: 800px;
  margin: 32px auto;
  padding: 0 16px;
`

export const BoletoWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

export const BoletoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #E5E5E5;
  background: #F8F9FA;
`

export const BankInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const BankName = styled.h2`
  font-size: 20px;
  color: #1A1A1A;
  margin: 0;
`

export const BankCode = styled.span`
  font-size: 16px;
  color: #666666;
  padding: 4px 8px;
  background: #E5E5E5;
  border-radius: 4px;
`

export const Amount = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #1A1A1A;
`

export const BoletoContent = styled.div`
  padding: 24px;
`

export const Instructions = styled.p`
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
  margin-bottom: 24px;
  padding: 16px;
  background: #F8F9FA;
  border-radius: 4px;
`

export const InfoGrid = styled.div`
  display: grid;
  gap: 16px;
  margin-bottom: 24px;
`

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #E5E5E5;

  &:last-child {
    border-bottom: none;
  }
`

export const InfoLabel = styled.span`
  font-size: 14px;
  color: #666666;
`

export const InfoValue = styled.span`
  font-size: 14px;
  color: #1A1A1A;
  font-weight: 500;
`

export const BarcodeSection = styled.div`
  margin: 24px 0;
  padding: 24px;
  background: #F8F9FA;
  border-radius: 4px;
`

export const PaymentLineSection = styled.div`
  margin: 24px 0;
  padding: 16px;
  background: #F8F9FA;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`

export const PaymentLineLabel = styled.span`
  font-size: 14px;
  color: #666666;
  min-width: 100px;
`

export const PaymentLineValue = styled.span`
  font-family: monospace;
  font-size: 16px;
  color: #1A1A1A;
  letter-spacing: 0.5px;
  flex: 1;
  word-break: break-all;
`

export const CopyButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: transparent;
  color: #748351;
  border: 1px solid #748351;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(116, 131, 81, 0.1);
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

export const ActionButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 24px;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #748351;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 200px;

  &:hover {
    background-color: #5a6840;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`

export const BoletoWarning = styled.div`
  margin-top: 24px;
  padding: 16px;
  background-color: #FFF3CD;
  border: 1px solid #FFEEBA;
  border-radius: 4px;
  color: #856404;
  font-size: 14px;
  text-align: center;
`

export const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #666666;
  margin-top: 32px;
`

export const ErrorMessage = styled.div`
  text-align: center;
  font-size: 18px;
  color: #DC3545;
  margin-top: 32px;
`

