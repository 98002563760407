/* eslint-disable import/no-duplicates */
/* eslint-disable import/no-extraneous-dependencies */

import { Radio } from 'antd'

import DatePickerPopup from 'new-components/CustomDatePicker'
import Select from 'new-components/Select'

import Loading from 'components/Loading'
import { useScheduling } from 'hooks/useScheduling'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import {
  bannerAgendamentoJaneiro,
  schedulingImage
} from 'assets/img'

import useMediaQuery from 'hooks/useMediaQuery'
import { IPrescriberInfo } from 'hooks/useScheduling/types'
import { extractFilterType, parseFiltersList } from 'pages/AppointmentScheduling/schedulingUtils'
import { BackButton } from 'pages/AppointmentScheduling/styles'
import { FaArrowLeft } from 'react-icons/fa'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import api from 'services/api'
import { MOBILE_WIDTH } from 'utils/constants'
import When from 'utils/when'
import * as Styles from './styles'

export type FilterBy = 'expertise' | 'pathology' | 'prescriber'
interface InputProps {
  id: string
  placeholder: string
  options: {
    value: string
    id: number
  }[]
}

interface IPrescriberResponse {
  prescriber: IPrescriberInfo
  success: boolean
}

export default function FilterForm() {
  const history = useHistory()
  const location = useLocation()
  const isMobile = useMediaQuery(MOBILE_WIDTH)
  const { setValue, watch, control } = useFormContext()

  const {
    next,
    expertisesList,
    pathologiesList,
    professionalsList,
    loading,
    loadingLists,
    getAvailableDates,
    setPrescribersList,
    setAvailableDatesState,
    availableDatesState,
    getPrescribers,
    resetContextValues,
    selectedPrescriber,
    setSelectedPrescriber,
    setLoading
  } = useScheduling()

  const currentFilter = watch('filter')
  const currentDate = watch('scheduling_date')

  const [filterBy, setFilterBy] = useState<FilterBy>(currentFilter ? extractFilterType(currentFilter) : 'expertise')

  const [screenState, setScreenState] = useState<'selectFilter' | 'selectDate'>(
    currentDate ? 'selectDate' : 'selectFilter'
  )

  const urlParams = useLocation().search
  const url = urlParams
    ? `https://atendimento.cannect.life/atendimento-cannect${urlParams}`
    : `https://atendimento.cannect.life/atendimento-cannect?utm_medium=organic&utm_source=whatsa%5B%E2%80%A6%5Dcannect_organic_geral&utm_content=cannect&utm_term=botao`

  const clearSearchParams = () => {
    history.push({
      pathname: history.location.pathname,
      search: ''
    })
  }

  const handleChangeFilterType = (e: any) => {
    setFilterBy(extractFilterType(e.target.value))
    setValue('filter', e.target.value)
    if (location.search) {
      clearSearchParams()
    }
    resetValues()
  }

  const handleSelectFilter = (type: FilterBy, id: string | Date | null) => {
    history.push({
      pathname: history.location.pathname,
      search: `?${type}_id=${id}`
    })
    setFilterBy(type)
    setValue('filter', `${type}:${id}`)
    getAvailableDates(type, id as string)
    setScreenState('selectDate')
    resetValues()
  }

  const resetValues = () => {
    setPrescribersList([])
    setValue('scheduling_date', null)
    setValue('scheduling_time', '')
    setValue('prescriber', null)
    setSelectedPrescriber(null)
    setAvailableDatesState([])
  }

  const handleSelectDate = async (date: string) => {
    setPrescribersList([])
    setValue('scheduling_time', null)
    setValue('prescriber', null)
    const result = await getPrescribers(date, currentFilter)

    if (result && result.success) {
      next()
    }
  }

  const handlePrescriberNotFound = async () => {
    resetContextValues()
    resetValues()
    setScreenState('selectFilter')
    history.push('/agendamento')
    setSelectedPrescriber(null)
  }

  const getPrescriberById = async (prescriberId: string) => {
    if (!prescriberId) return

    setLoading(true)

    try {
      const prescriberPersisted = await api.get<IPrescriberResponse>(`/scheduling/prescriber/${prescriberId}`)

      if (!prescriberPersisted.data?.prescriber) {
        handlePrescriberNotFound()
      }

      if (prescriberPersisted.data.prescriber) {
        setSelectedPrescriber(prescriberPersisted.data.prescriber)
      }
    } catch (err) {
      toast.error('Prescritor não encontrado')
      handlePrescriberNotFound()
    } finally {
      setLoading(false)
    }
  }

  const parsedExpertises = useMemo(() => parseFiltersList(expertisesList, 'expertise'), [expertisesList])
  const parsedPathologies = useMemo(() => parseFiltersList(pathologiesList, 'pathology'), [pathologiesList])
  const parsedPrescribers = useMemo(() => parseFiltersList(professionalsList, 'prescriber'), [professionalsList])

  const selectFilters: Record<FilterBy, InputProps> = {
    expertise: {
      id: 'expertise-filter',
      placeholder: 'Selecione a especialidade',
      options: parsedExpertises
    },
    pathology: {
      id: 'pathology-filter',
      placeholder: 'Selecione o sintoma',
      options: parsedPathologies
    },
    prescriber: {
      id: 'prescriber-filter',
      placeholder: 'Selecione o profissional',
      options: parsedPrescribers
    }
  }

  const currentSelectedFilter = selectFilters[filterBy]
  const searchParams = new URLSearchParams(location.search)
  const prescriberId = searchParams.get('prescriber_id')

  useEffect(() => {
    if (!prescriberId) return

    getPrescriberById(prescriberId)
  }, [prescriberId])

  useEffect(() => {
    if (!location.search || currentDate) return
    const params = new URLSearchParams(location.search)

    const filters = ['expertise', 'pathology', 'prescriber']

    filters.some((filter) => {
      const id = params.get(`${filter}_id`)
      if (id) {
        handleSelectFilter(filter as FilterBy, id)

        return true
      }
      return false
    })
  }, [location.search])

  return availableDatesState && currentFilter && screenState === 'selectDate' ? (
    <Styles.SelectDateContainer>
      <BackButton onClick={() => setScreenState('selectFilter')}>
        <FaArrowLeft style={{ marginRight: '8px' }} />
        Voltar
      </BackButton>
      <Loading loading={loading} />
      <Styles.SelectDateWithPrescriber>
        {/* {selectedPrescriber && (
          <Styles.SelectDatePrescriberInfo>
            <PrescriberCard
              key={selectedPrescriber.id}
              prescriberInfo={selectedPrescriber}
              displayAvailableTimes={false}
            />
          </Styles.SelectDatePrescriberInfo>
        )} */}

        <Styles.SelectDateContent>
          <span className="select-date-title-content">Selecione a data</span>
          <DatePickerPopup
            availableDates={availableDatesState}
            onSelectDate={handleSelectDate}
            isPopup={false}
            withHeading={false}
            onClose={() => setScreenState('selectFilter')}
          />
        </Styles.SelectDateContent>
      </Styles.SelectDateWithPrescriber>
    </Styles.SelectDateContainer>
  ) : (
    <>
      {isMobile ? (
        <img
          src={bannerAgendamentoJaneiro}
          alt="Promoção do mes do acesso"
          className="banner-mes-do-acesso"
          width="100%"
          style={{ marginBottom: '25px', marginTop: '15px' }}
        />
      ) : (
        <img
          src={bannerAgendamentoJaneiro}
          alt="Promoção do mes do acesso"
          className="banner-mes-do-acesso"
          width="100%"
          style={{ marginBottom: '25px', marginTop: '15px' }}
        />
      )}
      <Styles.Wrapper>
        <Styles.Container>
          <Loading loading={loading} />
          <Styles.HeaderContainer>
            <Styles.HeaderContent>
              <Styles.HeaderTitle>Agendamento de consultas</Styles.HeaderTitle>

              <Styles.HeaderSubtitle>Comece filtrando por especialidades ou sintomas:</Styles.HeaderSubtitle>
            </Styles.HeaderContent>
          </Styles.HeaderContainer>
          <Styles.RadioGroup>
            <Radio checked={filterBy === 'expertise'} value="expertise" onChange={handleChangeFilterType}>
              Filtrar por especialidade
            </Radio>
            <Radio checked={filterBy === 'pathology'} value="pathology" onChange={handleChangeFilterType}>
              Filtrar por sintomas
            </Radio>
            <Radio checked={filterBy === 'prescriber'} value="prescriber" onChange={handleChangeFilterType}>
              Filtrar por nome do profissional
            </Radio>
          </Styles.RadioGroup>

          <Styles.FieldsContainer>
            <div className="scheduling-select">
              <Controller
                control={control}
                name="filter"
                render={({ field }) => (
                  <Select
                    canSearch
                    isLoading={loadingLists}
                    id={currentSelectedFilter.id}
                    label=""
                    height="55px"
                    placeholder={currentSelectedFilter.placeholder}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value)
                      if (value) {
                        const [type, id] = value.split(':')
                        handleSelectFilter(type as FilterBy, id)
                      }
                    }}
                    options={currentSelectedFilter.options as any}
                  />
                )}
              />
            </div>
          </Styles.FieldsContainer>
          <Styles.ExternalLink target="_blank" href={url}>
            Prefere agendar via WhatsApp? Clique aqui.
          </Styles.ExternalLink>
        </Styles.Container>
        <When expr={!isMobile}>
          <Styles.Image src={schedulingImage} alt="Agendamento de consultas" />
        </When>
      </Styles.Wrapper>
    </>
  )
}
