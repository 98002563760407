export type CardBrand =
  | 'visa'
  | 'mastercard'
  | 'amex'
  | 'discover'
  | 'jcb'
  | 'diners'
  | 'elo'
  | 'hipercard'
  | 'maestro'
  | 'alipay'
  | 'paypal'
  | undefined

export const getCardBrand = (cardNumber: string): CardBrand => {
  const cleanNumber = cardNumber.replace(/\D/g, '')

  const patterns = {
    visa: /^4/,
    mastercard: /^(5[1-5]|2[2-7])/,
    amex: /^3[47]/,
    discover: /^6(?:011|5)/,
    jcb: /^35/,
    diners: /^3(?:0[0-5]|[68])/,
    elo: /^(4011|431274|438935|451416|457393|4576|457631|457632|504175|627780|636297|636368|636369)/,
    hipercard: /^(606282|637095|637599|637609|637612)/,
    maestro: /^(5018|5020|5038|5893|6304|6759|6761|6762|6763)/
  }

  const foundBrand = Object.entries(patterns).find(([brand, pattern]) => pattern.test(cleanNumber))
  return foundBrand ? (foundBrand[0] as CardBrand) : undefined
}

export const formatCardNumber = (value: string): string => {
  if (!value) return ''

  const cleanValue = value.replace(/\D/g, '')
  const brand = getCardBrand(cleanValue)

  // Formato específico para Amex (4-6-5)
  if (brand === 'amex') {
    return cleanValue
      .replace(/(\d{4})/, '$1 ')
      .replace(/(\d{4}) (\d{6})/, '$1 $2 ')
      .replace(/(\d{4}) (\d{6}) (\d{5})/, '$1 $2 $3')
      .trim()
  }

  // Formato padrão (4-4-4-4)
  return cleanValue.replace(/(\d{4})/g, '$1 ').trim()
}

export const formatExpiryDate = (month: string, year: string): string => {
  return `${month.padStart(2, '0')}/${year.slice(-2)}`
}
