import { Copy } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from 'services/api'
import Modal from './Modal'
import { BarcodeComponent } from './components/Barcode'
import * as S from './styles'
import { getBankInfo } from './utils/bankInfo'

interface ChargeResponse {
  success: boolean
  order: {
    id: number
    uuid: string
    amount: string
    originCheckout: string
  }
  order_paid: boolean
  charge: {
    id: number
    amount: number
    status: string
    payment_method: string
    transactions: Array<{
      payments_methods: Array<{
        type: string
        nosso_numero: string
        bank: string
        document_number: string
        instructions: string
        due_at: string
        barcode: string
        url: string
        line: string
      }>
    }>
  }
}

const Cobranca: React.FC = () => {
  const { type, order_id, charge_id } = useParams<{ type: string; order_id: string; charge_id: string }>()
  const [chargeData, setChargeData] = useState<ChargeResponse | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalMessage, setModalMessage] = useState('')
  const [copied, setCopied] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const fetchChargeData = async () => {
      try {
        const response = await api.get<ChargeResponse>(`payments/charge/${order_id}/${charge_id}`)
        setChargeData(response.data)

        if (response.data.order_paid) {
          setModalMessage(
            'Este pedido já foi pago. Você será redirecionado para a página do pedido em alguns segundos.'
          )
          setIsModalOpen(true)
        } else if (response.data.charge.status === 'paid') {
          setModalMessage(
            'Esta cobrança já foi paga. Você será redirecionado para a página do pedido em alguns segundos.'
          )
          setIsModalOpen(true)
        } else if (response.data.charge.status === 'refunded') {
          setModalMessage(
            'Esta cobrança foi estornada. Você será redirecionado para a página do pedido em alguns segundos.'
          )
          setIsModalOpen(true)
        }

        if (isModalOpen) {
          setTimeout(() => {
            history.push(`/analise-cannect-wa?cart_id=${response.data.order.uuid}`)
          }, 4000)
        }
      } catch (err) {
        setError('Falha ao buscar dados da cobrança')
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    fetchChargeData()
  }, [order_id, charge_id, history, isModalOpen])

  const handleCopyBarcode = async (line: string) => {
    try {
      await navigator.clipboard.writeText(line)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    } catch (err) {
      console.error('Falha ao copiar linha digitável:', err)
    }
  }

  if (loading) {
    return <S.LoadingMessage>Carregando...</S.LoadingMessage>
  }

  if (error) {
    return <S.ErrorMessage>{error}</S.ErrorMessage>
  }

  if (!chargeData) {
    return <S.ErrorMessage>Nenhum dado de cobrança encontrado.</S.ErrorMessage>
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
  }

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(amount / 100)
  }

  const isDueDatePassed = (dueDate: string) => {
    const now = new Date()
    const due = new Date(dueDate)
    return now > due
  }

  const renderBoletoInfo = () => {
    if (chargeData.charge.payment_method !== 'boleto') return null

    const boletoInfo = chargeData.charge.transactions[0].payments_methods[0]
    const dueDatePassed = isDueDatePassed(boletoInfo.due_at)
    const bankInfo = getBankInfo(boletoInfo.bank)

    return (
      <S.Container>
        <S.BoletoWrapper>
          <S.BoletoHeader>
            <S.BankInfo>
              <S.BankName>{bankInfo.name}</S.BankName>
              <S.BankCode>{bankInfo.code}</S.BankCode>
            </S.BankInfo>
            <S.Amount>{formatCurrency(chargeData.charge.amount)}</S.Amount>
          </S.BoletoHeader>

          <S.BoletoContent>
            <S.Instructions>
              Efetue o pagamento no Banco mais próximo ou casa lotérica até a data de vencimento, ou pelo internet
              banking copiando o número do boleto e utilizando-o no aplicativo do seu Banco.
            </S.Instructions>

            <S.InfoGrid>
              <S.InfoRow>
                <S.InfoLabel>Nosso Número</S.InfoLabel>
                <S.InfoValue>{boletoInfo.nosso_numero}</S.InfoValue>
              </S.InfoRow>
              <S.InfoRow>
                <S.InfoLabel>Número do Documento</S.InfoLabel>
                <S.InfoValue>{boletoInfo.document_number}</S.InfoValue>
              </S.InfoRow>
              <S.InfoRow>
                <S.InfoLabel>Data de Vencimento</S.InfoLabel>
                <S.InfoValue>{formatDate(boletoInfo.due_at)}</S.InfoValue>
              </S.InfoRow>
              <S.InfoRow>
                <S.InfoLabel>Valor</S.InfoLabel>
                <S.InfoValue>{formatCurrency(chargeData.charge.amount)}</S.InfoValue>
              </S.InfoRow>
            </S.InfoGrid>

            {!dueDatePassed && boletoInfo.barcode && (
              <>
                <S.BarcodeSection>
                  <BarcodeComponent value={boletoInfo.barcode} />
                </S.BarcodeSection>

                <S.PaymentLineSection>
                  <S.PaymentLineLabel>Linha Digitável:</S.PaymentLineLabel>
                  <S.PaymentLineValue>{boletoInfo.line}</S.PaymentLineValue>
                  <S.CopyButton onClick={() => handleCopyBarcode(boletoInfo.line)}>
                    <Copy size={16} />
                    {copied ? 'Copiado!' : 'Copiar'}
                  </S.CopyButton>
                </S.PaymentLineSection>
              </>
            )}

            {dueDatePassed ? (
              <S.BoletoWarning>
                Atenção: A data de vencimento deste boleto já passou. Por favor, entre em contato com o suporte para
                gerar um novo boleto.
              </S.BoletoWarning>
            ) : (
              <S.ActionButtons>
                <S.ActionButton onClick={() => window.open(boletoInfo.url, '_blank')}>Imprimir Boleto</S.ActionButton>
                <S.ActionButton onClick={() => window.open(boletoInfo.url, '_blank')}>Visualizar Boleto</S.ActionButton>
              </S.ActionButtons>
            )}
          </S.BoletoContent>
        </S.BoletoWrapper>
      </S.Container>
    )
  }

  return (
    <>
      {renderBoletoInfo()}
      <Modal isOpen={isModalOpen}>{modalMessage}</Modal>
    </>
  )
}

export default Cobranca
