import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { MOBILE_WIDTH } from '@cannect/utils/constants'
import { AnimatePresence, motion } from 'framer-motion'
import { CreditCard, Plus, Receipt, Wallet } from 'lucide-react'
import React from 'react'
import * as S from './styles'

interface PaymentMethodSelectorProps {
  selectedMethod: string | null
  onSelectMethod: (method: string) => void
  totalAmount: number
  disabledPix?: boolean
  disabledCreditCard?: boolean
  disabledBoleto?: boolean
  hasMultipleMethods?: boolean
}

const PaymentMethodSelector: React.FC<PaymentMethodSelectorProps> = ({
  selectedMethod,
  onSelectMethod,
  totalAmount,
  disabledPix,
  disabledCreditCard,
  disabledBoleto,
  hasMultipleMethods
}) => {
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const singleMethods = [
    {
      id: 'credit_card',
      title: 'Cartão de Crédito',
      icon: <CreditCard size={24} />,
      description: 'Pague com seu cartão de crédito',
      disabled: disabledCreditCard
    },
    {
      id: 'pix',
      title: 'Pix',
      icon: <Wallet size={24} />,
      description: 'Pagamento instantâneo',
      disabled: disabledPix
    },
    {
      id: 'boleto',
      title: 'Boleto Bancário',
      icon: <Receipt size={24} />,
      description: 'Vencimento em 3 dias úteis',
      disabled: disabledBoleto
    }
  ]

  const combinedMethods = [
    {
      id: 'credit_card_credit_card',
      title: 'Dois Cartões',
      icon: <CreditCard size={24} />,
      secondIcon: <CreditCard size={24} />,
      description: 'Divida entre dois cartões',
      disabled: disabledCreditCard
    },
    {
      id: 'credit_card_pix',
      title: 'Cartão + Pix',
      icon: <CreditCard size={24} />,
      secondIcon: <Wallet size={24} />,
      description: 'Parte no cartão, parte no Pix',
      disabled: disabledCreditCard || disabledPix
    },
    {
      id: 'credit_card_boleto',
      title: 'Cartão + Boleto',
      icon: <CreditCard size={24} />,
      secondIcon: <Receipt size={24} />,
      description: 'Parte no cartão, parte no boleto',
      disabled: disabledCreditCard || disabledBoleto
    }
  ]

  return (
    <S.PaymentSelectorContainer>
      <S.PaymentSectionTitle>Escolha como deseja pagar</S.PaymentSectionTitle>

      <S.PaymentOptionsSection>
        <S.PaymentOptionsGroup>
          <S.GroupTitle>Pagamento único</S.GroupTitle>
          <S.OptionsRow>
            {singleMethods.map((method) => (
              <S.PaymentOption
                key={method.id}
                isSelected={selectedMethod === method.id}
                onClick={() => onSelectMethod(method.id)}
                disabled={method.disabled}
                isMobile={isMobile}>
                <div className="icon-container">{method.icon}</div>
                <div className="method-info">
                  <h3>{method.title}</h3>
                  <p>{method.description}</p>
                </div>
                <div className="amount">
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(totalAmount)}
                </div>
                <AnimatePresence>
                  {selectedMethod === method.id && (
                    <motion.div
                      className="check-indicator"
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                    />
                  )}
                </AnimatePresence>
              </S.PaymentOption>
            ))}
          </S.OptionsRow>
        </S.PaymentOptionsGroup>

        {hasMultipleMethods && (
          <S.PaymentOptionsGroup>
            <S.GroupTitle>Pagamento combinado</S.GroupTitle>
            <S.OptionsRow>
              {combinedMethods.map((method) => (
                <S.PaymentOption
                  key={method.id}
                  isSelected={selectedMethod === method.id}
                  onClick={() => onSelectMethod(method.id)}
                  disabled={method.disabled}
                  isMobile={isMobile}>
                  <div className="combined-icons">
                    <div className="icon-container">{method.icon}</div>
                    <Plus size={16} className="plus-icon" />
                    <div className="icon-container">{method.secondIcon}</div>
                  </div>
                  <div className="method-info">
                    <h3>{method.title}</h3>
                    <p>{method.description}</p>
                  </div>
                  <div className="amount">
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL'
                    }).format(totalAmount)}
                  </div>
                  <AnimatePresence>
                    {selectedMethod === method.id && (
                      <motion.div
                        className="check-indicator"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                      />
                    )}
                  </AnimatePresence>
                </S.PaymentOption>
              ))}
            </S.OptionsRow>
          </S.PaymentOptionsGroup>
        )}
      </S.PaymentOptionsSection>
    </S.PaymentSelectorContainer>
  )
}

export default PaymentMethodSelector
