import { usePrepareCart } from 'hooks/usePrepareCart'
import { Loader } from 'lucide-react'
import { useEffect, useState } from 'react'
import api from 'services/api'
import * as S from './styles'

const Boleto = ({ isValidateSubmit, setError, isCardBoleto = false, boletoAmount = 0 }) => {
  const [isGeneratingBoleto, setIsGeneratingBoleto] = useState(false)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [chargeId, setChargeId] = useState(null)
  const { lastOrderCreated, clearOrderData, setOrderId } = usePrepareCart()

  const isAllSupplements = lastOrderCreated?.items.every(
    (item: any) => item?.product?.class_id === 6 && item.product?.brand_id === 108
  )
  const originCheck = ['acolhimento-cannect', 'B2C', 'COD B', 'Recipe', 'COD C']

  const handleGenerateBoleto = async () => {
    setIsGeneratingBoleto(true)
    try {
      const payload = {
        order_id: lastOrderCreated.id,
        payments: [
          {
            paymentMethod: { type: 'boleto' },
            amount: Math.round(lastOrderCreated.amount * 100)
          }
        ]
      }

      const response = await api.post('/payments/charges', payload)

      if (response.data.success && response.data.responses && response.data.responses.length > 0) {
        const paymentResponse = response.data.responses[0]
        if (paymentResponse.last_transaction && paymentResponse.last_transaction.success) {
          setChargeId(paymentResponse.id)

          const documents = response.data?.documents

          const documentList = documents?.reduce(
            (acc, doc) => {
              acc[doc.type] = doc
              return acc
            },
            {} as Record<string, any>
          )

          if (!isAllSupplements) {
            const validateAnvisa = documentList?.ANVISA !== undefined || documentList?.PROCURACAO !== undefined

            if (originCheck.some((origin) => origin === lastOrderCreated?.originCheckout) && !validateAnvisa) {
              const validator = await api.get(`anvisaAuth/order/${lastOrderCreated.id}`)

              if (validator?.data?.success && validator?.data?.message === 'waitingDocs') {
                const response = await api.post('anvisaAuth/launchAnvisa', {
                  order_id: lastOrderCreated.id
                })
              } else if (validator?.data?.success && validator?.data?.message === 'inexist') {
                const response = await api.post('anvisaAuth/clickAutomation', {
                  order_id: lastOrderCreated.id
                })
              }
            }
          }
          setIsRedirecting(true)
        } else {
          throw new Error('Transação do boleto não foi bem-sucedida')
        }
      } else {
        throw new Error('Falha ao gerar boleto')
      }
    } catch (error) {
      console.error('Erro ao gerar boleto:', error)
      setError(true)
    } finally {
      setIsGeneratingBoleto(false)
    }
  }

  useEffect(() => {
    let intervalId

    const checkChargeStatus = async () => {
      if (chargeId) {
        try {
          const response = await api.get(`payments/charge/${lastOrderCreated.id}/${chargeId}`)
          if (response.data.success) {
            const transaction = response.data.charge.transactions.find(
              (t) => t.external_id === response.data.charge.last_transaction_external_id
            )
            if (transaction && transaction.status === 'generated') {
              localStorage.removeItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
              setOrderId(null)
              clearOrderData()
              clearInterval(intervalId)
              window.location.href = `/pagamento/boleto/${lastOrderCreated.id}/${chargeId}`
            }
          }
        } catch (error) {
          console.error('Erro ao verificar status do boleto:', error)
        }
      }
    }

    if (isRedirecting) {
      intervalId = setInterval(checkChargeStatus, 2000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [isRedirecting, chargeId, lastOrderCreated.id])

  if (isRedirecting) {
    return (
      <S.ConfirmationCard>
        <S.CardHeader>
          <h2>Redirecionando para o pagamento do boleto</h2>
        </S.CardHeader>
        <S.CardContent>
          <S.RedirectingScreen>
            <Loader className="mb-4 animate-spin" size={48} />
            <p>Por favor, aguarde. Você será redirecionado em instantes.</p>
          </S.RedirectingScreen>
        </S.CardContent>
      </S.ConfirmationCard>
    )
  }

  return (
    <S.ConfirmationCard>
      <S.CardHeader>
        <h2>Confirmação de Pagamento via Boleto</h2>
      </S.CardHeader>
      <S.CardContent>
        <S.OrderDetails>
          <div className="detail-row">
            <span>Valor total:</span>
            {isCardBoleto ? (
              <strong>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(boletoAmount)}
              </strong>
            ) : (
              <strong>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(lastOrderCreated.amount)}
              </strong>
            )}
          </div>
          <div className="detail-row">
            <span>Método de pagamento:</span>
            <strong>Boleto Bancário</strong>
          </div>
          <div className="detail-row">
            <span>Prazo de pagamento:</span>
            <strong>3 dias úteis</strong>
          </div>
        </S.OrderDetails>
        <S.InfoText>
          <p>Ao confirmar, você receberá um boleto bancário para realizar o pagamento.</p>
          <p>O pagamento deve ser realizado em até 3 dias úteis para garantir a confirmação do seu pedido.</p>
          <p>O prazo para compensação do pagamento é de até 3 dias úteis.</p>
        </S.InfoText>
        <S.ButtonContainer>
          {!isCardBoleto && (
            <S.Button onClick={handleGenerateBoleto} disabled={isGeneratingBoleto || isValidateSubmit}>
              {isGeneratingBoleto ? (
                <>
                  <Loader className="mr-2 animate-spin" />
                  Gerando boleto...
                </>
              ) : (
                'Gerar Boleto'
              )}
            </S.Button>
          )}
        </S.ButtonContainer>
      </S.CardContent>
    </S.ConfirmationCard>
  )
}

export default Boleto
