interface BankInfo {
  [key: string]: {
    name: string
    code: string
  }
}

export const BANK_INFO: BankInfo = {
  '001': { name: 'Banco do Brasil', code: '001' },
  '033': { name: 'Santander', code: '033' },
  '104': { name: 'Caixa Econômica Federal', code: '104' },
  '197': { name: 'Stone', code: '197' },
  '198': { name: 'Pagar.me', code: '198' },
  '237': { name: 'Bradesco', code: '237' },
  '341': { name: 'Itaú', code: '341' },
  '376': { name: 'JPMorgan', code: '376' },
  '745': { name: 'Citibank', code: '745' }
}

export const getBankInfo = (bankCode: string) => {
  return BANK_INFO[bankCode] || { name: 'Banco', code: bankCode }
}
