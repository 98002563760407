import styled from 'styled-components'
import { temporaryTheme } from 'styles/theme'

export const ModalContent = styled.div<any>`
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 24px;

  .icon-box {
    margin-bottom: 16px;
    svg {
      width: 48px;
      height: 48px;
      color: ${({ color }) => (color === 'success' ? temporaryTheme.colors.sitePrimary : '#FF3B3B')};
    }
  }

  .error-title {
    font-family: 'Nunito', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: ${temporaryTheme.colors.darkGrey};
    margin-bottom: 16px;
    max-width: 280px;
  }

  .error-details {
    width: 100%;
    /*background: ${({ color }) => (color === 'success' ? '#F0F9F0' : '#FFF5F5')};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;

    .detail-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 8px;
      text-align: left;

      &:last-child {
        margin-bottom: 0;
      }

      .label {
        font-size: 14px;
        font-weight: 600;
        color: ${temporaryTheme.colors.darkGrey};
        margin-bottom: 4px;
      }

      .value {
        font-size: 14px;
        color: ${temporaryTheme.colors.grey};
      }

      .card-info {
        display: flex;
        align-items: center;
        gap: 8px;

      }
    }
    */

    .error-group {
      margin-bottom: 24px;
      padding: 16px;
      background: ${({ color }) => (color === 'success' ? '#F0F9F0' : '#FFF5F5')};
      border-radius: 8px;

      h4 {
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
        font-size: 16px;
        color: ${temporaryTheme.colors.darkGrey};
        margin-bottom: 16px;
      }

      .detail-item {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .label {
          font-size: 14px;
          font-weight: 600;
          color: ${temporaryTheme.colors.darkGrey};
          margin-bottom: 8px;
          display: block;
        }

        .value {
          font-size: 15px;
          color: ${temporaryTheme.colors.grey};
          display: block;
        }

        .card-info {
          background: #f8f8f8;
          padding: 12px;
          border-radius: 6px;
          margin-top: 4px;

          .value {
            font-family: monospace;
            font-size: 16px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .action-box {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: 100%;
      font-family: 'Nunito', sans-serif;
      font-weight: 600;
      font-size: 16px;
      padding: 12px;
      background-color: ${temporaryTheme.colors.sitePrimary};
      border: none;
      border-radius: 4px;
      color: white;
      cursor: pointer;
      transition: background-color 0.2s;

      &:hover {
        background-color: ${temporaryTheme.colors.sitePrimaryDark};
      }
    }
  }
`
